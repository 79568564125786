import React, { useEffect } from "react";
import { graphql } from "gatsby";

// components
import { SortedCards, CollectionsLinks, Seo } from "../../components";
import Layout from "../../components/Layout";

const Editions = ({ data }) => {
  const books = data.editions.nodes;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  return (
    <Layout>
      <Seo title="Editions" />
      <CollectionsLinks />
      <SortedCards books={books} title="Editions" />
    </Layout>
  );
};

export const query = graphql`
  query GetEditions {
    editions: allAirtable(
      filter: {
        data: { categories: { eq: "Editions" }, status: { eq: "Available" } }
      }
      sort: { fields: data___Release_Date, order: DESC }
    ) {
      nodes {
        data {
          ...CardParts
        }
      }
    }
  }
`;

export default Editions;
